<template>
  <div class="bdbr-3 bd-radius fs14 weight-700 p10">
    <p class="fs15 m-b-10">
      <b>{{ $t('FLIGHTS.BAGGAGE_INFO') }}</b>
    </p>
    <div class="d-flex align-items-center baggage-item">
      <img src="/assets/img/icons/V.svg" alt="" class="baggage-option m-b-5">
      <div class="d-flex align-items-center justify-content-between baggage-data p-b-5 w-100">
        <div class="d-flex align-items-center">
          <img src="/assets/img/icons/side bag.svg" alt="">
          <p class="mx10">{{ $t('FLIGHTS.PERSONAL_ITEM') }}</p>
        </div>
        <p class="baggage-state">{{ baglimit.personal_item_weight ? baglimit.personal_item_weight : '5' }} Kg</p>
      </div>
    </div>
    <div class="d-flex align-items-center baggage-item m-t-5">
      <img :src="'/assets/img/icons/' + (bagsprice && bagsprice.hand ? '$' : 'V') + '.svg'" alt="" class="baggage-option m-b-5">
      <div class="d-flex align-items-center justify-content-between baggage-data p-b-5 w-100">
        <div class="d-flex align-items-center">
          <img src="/assets/img/icons/caryonbag.svg" alt="">
          <p class="mx10">{{ $t('FLIGHTS.CABIN_BAGGAGE') }}</p>
        </div>
        <p class="baggage-state">{{ bagsprice && bagsprice.hand ? $t('FLIGHTS.PAID_OPTION') : $t('FLIGHTS.FREE') }} {{ baglimit.hand_weight ? '(' + baglimit.hand_weight + 'Kg)' : '' }}</p>
      </div>
    </div>
    <div class="d-flex align-items-center baggage-item m-t-5">
      <img :src="'/assets/img/icons/' + (bagsprice && (bagsprice.first_baggage || bagsprice.first_baggage === 0) ? (bagsprice.first_baggage === 0 ? 'V' : '$') : 'X') + '.svg'" alt="" class="baggage-option m-b-5">
      <div class="d-flex align-items-center justify-content-between baggage-data p-b-5 w-100">
        <div class="d-flex align-items-center">
          <img src="/assets/img/icons/checkedbag.svg" alt="">
          <p class="mx10">{{ $t('FLIGHTS.CHECKED_BAGGAGE') }}</p>
        </div>
        <p class="baggage-state">{{ bagsprice && (bagsprice.first_baggage || bagsprice.first_baggage === 0) ? ((bagsprice.first_baggage === 0 ? $t('FLIGHTS.FREE') : $t('FLIGHTS.PAID_OPTION')) +  (baglimit.hold_weight ? ' (' + baglimit.hold_weight + 'Kg)' : '')) : $t('FLIGHTS.NOT_AVAILABLE') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaggageInfo",
  props: {
    baglimit: {
      type: Object,
      default: null
    },
    bagsprice: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.baggage-item {
  .baggage-option {
    margin-right: 10px;
    width: 12px;
  }
  .baggage-data {
    border-bottom: 1px solid #c5c5c5;
    .baggage-state {
      width: 100px;
    }
  }
}

.rtl-type {
  .baggage-item {
    .baggage-option {
      margin-right: unset;
      margin-left: 10px;
    }
  }
}
</style>