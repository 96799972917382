<template>
  <div class="bg-blue4 d-flex fs14 p10">
    <img src="/assets/img/icons/info.svg" alt="" class="info-icon">
    <div>
      <p class="m-b-10"><b>COVID-19 {{ $t('ALERT.INFORMATION') }}</b></p>
      <p v-html="$t('COMMON.COVID_TXT')">
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CovideNotify"
}
</script>

<style lang="scss" scoped>
.info-icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.rtl-type {
  .info-icon {
    margin-right: unset;
    margin-left: 10px;
  }
}
</style>