<template>
  <div v-if="routes && routes.length" class="bdbr-3 bd-radius p5 fs14 bg-grey6">
    <p class="m-b-15">
      <b>{{ $t('FLIGHTS.TO')}} {{ lastRoute.to.city_name}}</b>
    </p>
    <div class="d-flex align-items-center calendar-block">
      <img src="/assets/img/icons/calendar-day-light.svg" alt="" class="mx10">
      {{ firstRoute.departure_date_local | getDayName }} {{ firstRoute.departure_date_local | flightsFormatDate }}
    </div>
    <div class="d-flex ticket-table m-t-5">
      <div class="title-column">
        <fragment
                v-for="(route, index) in routes"
                :key="index + '_title_' + route.id"
        >

          <div class="df-c bg-white left-white-column"
          :class="{'m-t-10': index === 0}">
            <b>{{ route.departure_date_local | flightsFormatTime }}</b>
          </div>
          <div class="df-c">
            <!--<span >{{ route.airline }}</span>-->
            <div :style="{'background': 'url(https://images.kiwi.com/airlines/64x64/' + route.airline.code + '.png?default=airline.png)'}" class="airline-logo-small">
              <!--{{ route.airline.code }}-->
            </div>
            <img v-if="false" src="/assets/img/icons/luftansa.svg" alt="" class="company-icon">
          </div>
          <div class="df-c bg-white left-white-column">
            <b>{{ route.arrival_date_local | flightsFormatTime }}</b>
          </div>


          <div v-if="index !== (routes.length - 1)" class="" :class="(routes[index + 1].bags_recheck_required ? 'triple-column' : 'double-column')">
          </div>

          <div v-if="index === (routes.length - 1)"></div>

        </fragment>
      </div>
      <div class="data-column">
        <fragment
          v-for="(route, index) in routes"
          :key="index + '_data_' + route.id"
        >

          <div v-if="index !== 0" class=" pt10" :class="(route.bags_recheck_required ? 'triple-column' : 'double-column')">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/stopwatch-light.svg" alt=""  class="mx10">
              <p>{{ timeDifferenceHours(routes[index - 1].arrival_date_utc, route.departure_date_utc) }}h {{ timeDifferenceMinutes(routes[index - 1].arrival_date_utc, route.departure_date_utc) }}m {{ $t('FLIGHTS.LAYOVER') }}</p>
            </div>
            <div v-if="route.guarantee" class="d-flex align-items-center m-t-10">
              <img src="/assets/img/icons/check.svg" alt="" class="mx10">
              <p class="fs11">{{ $t('FLIGHTS.CONNECTION_PROTECTED_BY_CARRIER') }}</p>
            </div>
            <div v-if="route.bags_recheck_required" class="d-flex align-items-center m-t-10">
              <img width="25" src="/assets/img/icons/exclamation-triangle-light.svg" alt="" class="mx10">
              <p class="fs11">{{ $t('FLIGHTS.BAGS_RECHECK_REQUIRED') }}</p>
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-center bg-white right-white-column px5"
               :class="{'m-t-10': index === 0}">
            <div class="mx15">
              <p><b>{{ route.from.city_name }}</b></p>
              <p class="fs12">{{ route.from.airport_name }} ({{ route.from.airport_code }})</p>
            </div>
            <div class="blue-badge">
              {{ route.cabin_class ? $t(`FLIGHTS.CABIN_CLASS_${route.cabin_class.toUpperCase()}`) : '-' }}
            </div>
          </div>
          <div class="d-flex align-items-center px10">
            <div>{{ route.flight_no }}</div>
            <img class="mx10" src="/assets/img/icons/plane-light.svg" alt="">
            <div class="white-badge mx10">
              {{ route.airline.name || route.airline.code }}
            </div>
            <div class="blue-badge">
              {{ timeDifferenceByDates(route.departure_date_utc, route.arrival_date_utc) }}
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center bg-white right-white-column px5">
            <div class="mx15">
              <p><b>{{ route.to.city_name }}</b></p>
              <p class="fs12">{{ route.to.airport_name }} ({{ route.to.airport_code }})</p>
            </div>
          </div>

          <div v-if="index === (routes.length - 1)" class="d-flex align-items-center px10">
            <img src="/assets/img/icons/map-marker-alt-light-black.svg" alt="">
            <div class="mx15">
              <p class="fs12">{{ $t('FLIGHTS.ARRIVED_DESTINATION') }}</p>
              <p><b>{{ route.to.city_name }}</b></p>
            </div>
          </div>
        </fragment>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FligntTicket',
  props: {
    routes: {
        type: [Array, Object, Boolean],
        default: null
    }
  },
  computed: {
    firstRoute() {
      return this.routes && this.routes.length
          ? this.routes[0] : null;
    },
    lastRoute() {
      return this.routes && this.routes.length
          ? this.routes[this.routes.length - 1] : null;
    },
  }
}
</script>

<style lang="scss" scoped>
  .airline-logo-small {
    width: 80px;
    height: 35px;
    background-repeat: no-repeat !important;
    background-size: auto 120% !important;
    background-position: center center !important;
  }
  .ticket-table {
    .title-column {
      border-right: 1px solid #d6d6d6;
      width: 70px;
      &>div {
        height: 40px;
      }
    }
    .data-column {
      width: calc(100% - 70px);
      &>div {
        height: 40px;
      }
    }
    .left-white-column {
      border: 1px solid #c5c5c5;
      border-right: none;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    .right-white-column {
      border: 1px solid #c5c5c5;
      border-left: none;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .company-icon {
      // height: 10px;
      width: 56px;
    }
    .double-column {
      height: 70px !important;
    }
    .triple-column {
      height: 105px !important;
    }
  }
  .calendar-block {
    padding-left: 48px;
  }
  .rtl-type {
    .calendar-block {
      padding-left: unset;
      padding-right: 48px;
    }
    .ticket-table {
      .title-column {
        border-right: unset;
        border-left: 1px solid #d6d6d6;
      }
      .left-white-column {
        border-right: 1px solid #d6d6d6;
        border-left: none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      .right-white-column {
        border-left: 1px solid #d6d6d6;
        border-right: none;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
</style>