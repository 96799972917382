<template>
  <div class="bg-white fs14 result-block bd-radius">
    <p class="text-center" v-show="isShowTitle"><b>{{ $t('FLIGHTS.ORDER_SUMMARY') }}</b></p>
    <div class="d-flex justify-content-between align-items-center m-b-15">
      <p>{{ $t('FLIGHTS.PASSENGERS') }}</p>
      <p>{{ passengers_num }}</p>
    </div>
    <div class="d-flex justify-content-between align-items-center m-b-15">
      <p>{{ $t('FLIGHTS.PRICE_PER_PASSENGER') }}</p>
      <p>{{ flight_price_per_person | formatPrice }} ₪</p>
    </div>
    <div class="d-flex justify-content-between align-items-center m-b-15">
      <p>{{ $t('FLIGHTS.TOTAL_ORDER_PRICE') }}</p>
      <p>{{ flight_price | formatPrice }} ₪</p>
    </div>
    <div class="d-flex justify-content-between align-items-center m-b-15">
      <p>{{ $t('FLIGHTS.CABIN_BAGGAGE') }}</p>
      <p>{{ hand_bag_sum | formatPrice }} ₪</p>
    </div>
    <div class="d-flex justify-content-between align-items-center m-b-5">
      <p>{{ $t('FLIGHTS.CHECKED_BAGGAGE') }}</p>
      <p>{{ hold_bag_sum | formatPrice }} ₪</p>
    </div>
    <div class="total-value d-flex justify-content-between align-items-center m-b-10 fs16 pt15">
      <p><b>{{ $t('FLIGHTS.TOTAL') }} (ILS)</b></p>
      <p><b>{{ total_price | formatPrice }} ₪</b></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlightResult",
  props: {
    isShowTitle: {
      type: Boolean,
      default: false
    },
    flight: {
      type: Object,
      default: null
    },
    passengers: {
      type: Array,
      default: []
    }
  },
  computed: {
    passengers_num() {
      return this.passengers ? this.passengers.length : 0;
    },
    hand_bags() {
      let hand_bags = 0;

      if (this.passengers) {
        hand_bags = this.passengers.reduce((accumulator, pass) => {
          return accumulator + pass.hand_bag;
        }, 0);
      }

      return hand_bags;
    },
    hold_bags() {
      let hold_bags = 0;

      if (this.passengers) {
        hold_bags = this.passengers.reduce((accumulator, pass) => {
          return accumulator + pass.hold_bag;
        }, 0);
      }

      return hold_bags;
    },
    flight_price() {
      return this.flight && this.flight.price && this.flight.price.orig_price
        ? this.flight.price.orig_price
        : (this.flight && this.flight.price && this.flight.price.price
          ? (this.flight.price.price)
          : 0);
    },
    flight_price_per_person() {
      return this.total_price && this.passengers_num ? this.total_price / this.passengers_num : 0;
    },
    hand_bag_price() {
      return this.flight && this.flight.bagsprice && this.flight.bagsprice.hand ? this.flight.bagsprice.hand : 0;
    },
    first_hold_bag_price() {
      return this.flight && this.flight.bagsprice && this.flight.bagsprice.first_baggage !== null ? this.flight.bagsprice.first_baggage : null;
    },
    second_hold_bag_price() {
      return this.flight && this.flight.bagsprice && this.flight.bagsprice.second_baggage !== null ? this.flight.bagsprice.second_baggage : null;
    },
    hand_bag_sum() {
      let hand_bag_sum = 0;

      if (this.passengers) {
        hand_bag_sum = this.passengers.reduce((accumulator, pass) => {
          return accumulator + pass.hand_bag * this.hand_bag_price;
        }, 0);
      }

      return hand_bag_sum;
    },
    hold_bag_sum() {
      let hold_bag_sum = 0;

      if (this.passengers) {
        hold_bag_sum = this.passengers.reduce((accumulator, pass) => {
          return accumulator + (pass.hold_bag === 2 ? this.second_hold_bag_price : pass.hold_bag * this.first_hold_bag_price);
        }, 0);
      }

      return hold_bag_sum;
    },
    total_price() {
        return this.flight_price + this.hand_bag_sum + this.hold_bag_sum;
    }
  }
}
</script>

<style lang="scss" scoped>
.result-block {
  padding: 10px 20px;
}

.total-value {
  border-top: 1px solid #002036;
}
</style>