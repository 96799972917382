<template>
  <div class="trip-summary bdbr-3 bd-radius d-flex align-items-center">
    <div class="flight-company">
      {{ $t('FLIGHTS.AIRLINES') }}:
      <div v-for="(airline, index) in flight.airlines" :style="{'background': 'url(https://images.kiwi.com/airlines/128x128/' + airline + '.png?default=airline.png)'}" class="airline-logo">
        <!--{{ airline }}-->
      </div>
      <!--<img src="/assets/img/icons/luftansa.svg" alt="" class="m-b-15">-->
      <!--<img src="/assets/img/icons/Austrian_Airlines.svg" alt="">-->
    </div>
    <div class="mx30">
      <p>{{ flight.to.city_name }}, {{ flight.to.country_name }}</p>
      <p v-if="false" class="d-flex align-items-center">
        <img src="/assets/img/icons/arrows-h-alt.svg" alt="">
        <span class="fs11">arrows-h-alt</span> 
      </p>
    </div>
    <template v-if="flight.return_routes && flight.return_routes.length">
      <div>
        {{ flight.from.airport_code }}
      </div>
      <div v-if="firstToRoute && lastToRoute" class="mx10 text-center">
        <p class="fs16">
          {{ firstToRoute.departure_date_local | flightsFormatDate }} {{ formattedDate(lastToRoute.arrival_date_local) !== formattedDate(firstToRoute.departure_date_local) ? ' - ' + formattedDate(lastToRoute.arrival_date_local) : '' }}
        </p>
        <p class="fs11">
          {{ firstToRoute.departure_date_local | flightsFormatTime }} - {{ lastToRoute.arrival_date_local | flightsFormatTime }}
        </p>
        <p class="fs11">
          {{ timeDifferenceText(firstToRoute.departure_date_utc, lastToRoute.arrival_date_utc, 'days') }} {{ timeDifferenceText(firstToRoute.departure_date_utc, lastToRoute.arrival_date_utc, 'hours') }} {{ timeDifferenceText(firstToRoute.departure_date_utc, lastToRoute.arrival_date_utc, 'minutes') }}
        </p>
      </div>
      <img src="/assets/img/icons/flight.svg" alt="">
      <div class="mx10">
        {{ flight.to.airport_code }}
      </div>
      <div v-if="firstReturnRoute && lastReturnRoute" class="mx10 text-center">
        <p class="fs16">
          {{ firstReturnRoute.departure_date_local | flightsFormatDate }} {{ formattedDate(lastReturnRoute.arrival_date_local) !== formattedDate(firstReturnRoute.departure_date_local) ? ' - ' + formattedDate(lastReturnRoute.arrival_date_local) : '' }}
        </p>
        <p class="fs11">
          {{ firstReturnRoute.departure_date_local | flightsFormatTime }} - {{ lastReturnRoute.arrival_date_local | flightsFormatTime }}
        </p>
        <p class="fs11">
          {{ timeDifferenceText(firstReturnRoute.departure_date_utc, lastReturnRoute.arrival_date_utc, 'days') }} {{ timeDifferenceText(firstReturnRoute.departure_date_utc, lastReturnRoute.arrival_date_utc, 'hours') }} {{ timeDifferenceText(firstReturnRoute.departure_date_utc, lastReturnRoute.arrival_date_utc, 'minutes') }}
        </p>
      </div>
      <div class="fs14">
        {{ flight.days_in_dest }} {{ $t('FLIGHTS.DAYS') }}
      </div>
    </template>
    <template v-else>
      <div v-if="firstToRoute && lastToRoute" class="mx10 text-center">
        <p class="fs16">
          {{ firstToRoute.departure_date_local | flightsFormatDate }} {{ formattedDate(lastToRoute.arrival_date_local) !== formattedDate(firstToRoute.departure_date_local) ? ' - ' + formattedDate(lastToRoute.arrival_date_local) : '' }}
        </p>
        <p class="fs11">
          {{ firstToRoute.departure_date_local | flightsFormatTime }} - {{ lastToRoute.arrival_date_local | flightsFormatTime }}
        </p>
        <p class="fs11">
          {{ timeDifferenceText(firstToRoute.departure_date_utc, lastToRoute.arrival_date_utc, 'days') }} {{ timeDifferenceText(firstToRoute.departure_date_utc, lastToRoute.arrival_date_utc, 'hours') }} {{ timeDifferenceText(firstToRoute.departure_date_utc, lastToRoute.arrival_date_utc, 'minutes') }}
        </p>
      </div>
    </template>

  </div>
</template>

<script>
export default {
  name: "TripSummary",
  props: {
    flight: {
      type: Object,
      default: null
    }
  },
  computed: {
    firstToRoute() {
      return this.flight.to_routes && this.flight.to_routes.length ? this.flight.to_routes[0] : null;
    },
    lastToRoute() {
      return this.flight.to_routes && this.flight.to_routes.length ? this.flight.to_routes[this.flight.to_routes.length - 1] : null;
    },
    firstReturnRoute() {
      return this.flight.return_routes && this.flight.return_routes.length ? this.flight.return_routes[0] : null;
    },
    lastReturnRoute() {
      return this.flight.return_routes && this.flight.return_routes.length ? this.flight.return_routes[this.flight.return_routes.length - 1] : null;
    }
  },
  methods: {
    timeDifferenceText(fromDate, toDate, type = 'minutes') {
        if (!['minutes', 'hours', 'days'].includes(type)) {
            type = 'minutes';
        }

        let formattedDifference = '';
        switch (type) {
            case 'minutes':
                formattedDifference = this.timeDifferenceMinutes(fromDate, toDate);
                formattedDifference = formattedDifference && formattedDifference != '0'
                    ? formattedDifference + 'm' : '';
                break;
            case 'hours':
                formattedDifference = this.timeDifferenceHours(fromDate, toDate);
                formattedDifference = formattedDifference && formattedDifference != '0'
                    ? formattedDifference + 'h' : '';
                break;
            case 'days':
                formattedDifference = this.timeDifferenceDays(fromDate, toDate);
                formattedDifference = formattedDifference && formattedDifference != '0'
                    ? formattedDifference + 'd' : '';
                break;
            default:
                break;

        }

        return formattedDifference;
    }
  }
}
</script>

<style lang="scss" scoped>
  .trip-summary {
    .airline-logo {
      margin: 5px 0;
      width: 80px;
      height: 50px;
      background-repeat: no-repeat !important;
      background-size: auto 120% !important;
      background-position: center center !important;
    }
    padding: 5px 10px;
    .flight-company {
      width: 80px;
      display: block;
    }
  }

  @media (max-width: 670px) {
    .trip-summary {
      flex-direction: column;
    }
  }
</style>